import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import GuestLogin from "../../components/authentication/GuestLogin";
import OtpLogin from "../../components/authentication/OtpLogin";
import LoginWithGoogle from "../../components/authentication/LoginWithGoogle";
import { useEffect, useRef } from "react";
import { captureFaceData, loadModels } from "../../utils/faceUtils";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  const largeVideoRef = useRef(null); // Reference for the larger video
  const smallVideoRef = useRef(null); // Reference for the smaller video
    useEffect(() => {
      const initializeApp = async () => {
        await loadModels();
    
        const hasCamera = await checkCameraPermissions();
        if (hasCamera) {
          setTimeout(() => {
            if (largeVideoRef.current) startVideo(largeVideoRef);
      if (smallVideoRef.current) startVideo(smallVideoRef);
          }, 500); 
        } else {
          alert('No camera found or permission denied.');
        }
      };
    
      initializeApp();
    }, []);

    const checkCameraPermissions = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
    
        if (videoDevices.length === 0) {
          alert('No camera found. Please check your device.');
          return false;
        }
    
        console.log(`✅ Found ${videoDevices.length} camera(s).`);
        return true;
      } catch (error) {
        console.error('❌ Error checking camera permissions:', error);
        return false;
      }
    };



  const startVideo = (videoRef) => {
    if (!videoRef.current) {
      console.warn("Video element not found yet!");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => console.error("❌ Error accessing camera:", error));
  };

  const captureFace = async () => {
    const isMobile = window.innerWidth < 900;
    const videoRefToUse = isMobile ? smallVideoRef : largeVideoRef;

    const faceData = await captureFaceData(videoRefToUse);
    if (!faceData || faceData.length === 0) {
      alert("❌ No face detected. Please try again.");
      return;
    }
    // Returning the descriptor from the first detected face
    return Array.from(faceData[0].descriptor);
  };

  return (
    <RootStyle title="Login | Clover Carte">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: -25, mb: 1 }}>
            Hi, Welcome Back
          </Typography>
          {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
          <video
        ref={largeVideoRef}
        autoPlay
        muted
        style={{
          width: '400px',
          height: '300px',
          // border: '2px solid black',
          marginTop: '20px',
          marginLeft:"25px",
          borderRadius:'10px',
          boxShadow:'1px 1px 10px red'
        }}
      />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 5, }}>
            <Stack>
            <Typography variant="h4" gutterBottom>
              Sign in to Clover Carte
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
            </Stack>
            <MHidden width="mdUp">
            <video
        ref={smallVideoRef}
        autoPlay
        muted
        style={{
          width: '100px',
          height: '100px',
          // border: '2px solid black',
          marginTop: '20px',
          // marginLeft:"25px",
          borderRadius:'10px',
          boxShadow:'1px 1px 10px red'
        }}
      />
      </MHidden>
          </Stack>

          <OtpLogin />
          <LoginForm handleFace={captureFace}  />
          <Stack sx={{ mt: 5 }} direction="column" spacing={2}>
            <GuestLogin />
            <LoginWithGoogle />
          </Stack>


          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
