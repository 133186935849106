import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Stack
} from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { RegisterForm } from "../../components/authentication/register";
import SignUpWithGoogle from "../../components/authentication/SignUpWithGoogle";
import GuestLogin from "../../components/authentication/GuestLogin";
import MachinePNG from "../../assets/images/1.png"
import { captureFaceData, loadModels } from "../../utils/faceUtils";
import { useEffect, useRef, useState } from "react";
import * as faceapi from 'face-api.js';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));


// ----------------------------------------------------------------------

export default function Register() {


  const videoRef = useRef();
  const smallVideoRef = useRef();

  const [faceData,setFaceData] =useState()

  useEffect(() => {
    const initializeApp = async () => {
      await loadModels();
  
      const hasCamera = await checkCameraPermissions();
      if (hasCamera) {
        setTimeout(() => {
          if (videoRef.current) startVideo(videoRef);
          if (smallVideoRef.current) startVideo(smallVideoRef);
        }, 500); 
      } else {
        alert('No camera found or permission denied.');
      }
    };
  
    initializeApp();
  }, []);
  
  
  const startVideo = async (videoRef) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
  
      // Assign the stream to the video element
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
  
      console.log('✅ Camera started successfully!');
    } catch (error) {
      console.error('❌ Error accessing camera:', error);
      alert('Error accessing the camera. Please allow camera permissions and try again.');
    }
  };
  
  
  const checkCameraPermissions = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
  
      if (videoDevices.length === 0) {
        alert('No camera found. Please check your device.');
        return false;
      }
  
      console.log(`✅ Found ${videoDevices.length} camera(s).`);
      return true;
    } catch (error) {
      console.error('❌ Error checking camera permissions:', error);
      return false;
    }
  };

  
  const handleCaptureFace = async () => {
    const isMobile = window.innerWidth < 900;
    const videoRefToUse = isMobile ? smallVideoRef : videoRef;
    if (!videoRefToUse.current || !faceapi.nets.ssdMobilenetv1.isLoaded) {
      alert('Camera is not ready or models are not loaded.');
      return;
    }
    
    const detections = await faceapi
      .detectAllFaces(videoRefToUse.current, new faceapi.SsdMobilenetv1Options())
      .withFaceLandmarks()
      .withFaceDescriptors();

    if (!detections.length) {
      alert('No face detected. Please try again.');
      return;
    }
    const faceDescriptors = detections.map((face) => ({
      descriptor: Array.from(face.descriptor),
    }));

    console.log('✅ Face data captured:', detections);
    return faceDescriptors;
  };

  return (
    <RootStyle title="Register | Clover Carte">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/login"
        >
          Login
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: -15, mb: 2 }}>
            Create an account and track your  orders
          </Typography>
          {console.log(videoRef.length)}
          {/* <img
            alt="register"
            src="/static/illustrations/illustration_register.png"
          // src={MachinePNG}
          /> */}

<video
        ref={videoRef}
        autoPlay
        muted
        style={{
          width: '400px',
          height: '300px',
          // border: '2px solid black',
          marginTop: '20px',
          marginLeft:"25px",
          borderRadius:'10px',
          boxShadow:'1px 1px 10px red'
        }}
      />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Get started absolutely free.
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              You are just one step away from fantastic taste.
            </Typography>
            <MHidden width="mdUp">
            <video
     ref={smallVideoRef}
     autoPlay
     muted
     style={{
       width: '100px',
       height: '100px',
       // border: '2px solid black',
       marginTop: '20px',
       // marginLeft:"25px",
       borderRadius:'10px',
       boxShadow:'1px 1px 10px red'
        }}
      />
      </MHidden>
          </Box>


          <RegisterForm face={faceData} handleCaptureFace={handleCaptureFace} />
          <Stack sx={{ mt: 5 }} direction="column" spacing={2}>
            {/* <GuestLogin /> */}
            <SignUpWithGoogle />
          </Stack>

          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 3 }}
          >
            By registering, I agree to Clover Carte&nbsp;
            <Link underline="always" sx={{ color: "text.primary" }}>
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" sx={{ color: "text.primary" }}>
              Privacy Policy
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
