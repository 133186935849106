import React from "react";
import { Counter } from "../../components/counter/counter";
//import  CURRENCY  from "../../constants/content.constant/CURRENCY";
import {
  ItemBox,
  Image,
  Information,
  Name,
  Price,
  Weight,
  Total,
  RemoveButton,
  WaitingListWarning
} from "./order-item.style";
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid } from "@mui/material";

export const OrderItemList = ({
  data,
  onDecrement,
  onIncrement,
  onRemove,
  warning
}) => {

  const { title, images, price, salePrice, unit, quantity,product_img } = data;
  const displayPrice = salePrice ? salePrice : price;
  return (
    <>
      <Grid container direction="column" spacing={2} sx={{ mt: 5 }}>
        <Grid>
          <ItemBox>
            <Counter
              value={quantity}
              onDecrement={onDecrement}
              onIncrement={onIncrement}
              variant="vertical"
            />
            <Image src={images?.url||product_img} />
            <Information>
              <Name className="text-nowrap" >{title}</Name>
              <Price>
                {/* {CURRENCY} */}Rs.
                {displayPrice}
              </Price>
              <Weight>
                {quantity} X {unit}
              </Weight>
            </Information>
            <div className="text-nowrap" style={{position:"absolute",right:15}} >
            <Total  >
              {/* {CURRENCY} */}Rs.
              {(quantity * displayPrice).toFixed(2)}
            </Total>
            <RemoveButton onClick={onRemove}>
              < CloseIcon />
            </RemoveButton>
            </div>
          </ItemBox>
        </Grid>

        <Grid  >
          <WaitingListWarning className="mx-2"> {warning} </WaitingListWarning>

        </Grid>

      </Grid>
      <Divider />


    </>
  );
};
