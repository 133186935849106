import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useDispatch } from "react-redux";
import { loginUserAsyncThunk } from "../../../store/slices/user.slice";
import { useNavigate } from 'react-router-dom';
import { ROUTES_URL } from "../../../constants/url.constant";
import { checkUser } from '../../../utils/checkUser';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function LoginForm({ handleFace }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUser = checkUser();
  const [isFace, setIsFace] = useState(false);
  const [isEmailPass, setIsEmailPass] = useState(false);
  const [faceData, setFaceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (isUser) {
      navigate(ROUTES_URL?.HOME, { replace: true });
    }
  }, [isUser, navigate]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const loginData = isFace
        ? { faceData, isFace: true }
        : { email: values.email, password: values.password, remember: values.remember, isFace: false };
      
      dispatch(loginUserAsyncThunk(loginData)).then((response) => {
        if (!response?.error) {
          navigate(ROUTES_URL?.HOME, { replace: true });
        }
      });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleFaceLogin = async () => {
    setIsLoading(true);
    const detectedFaceData = await handleFace();
    if (detectedFaceData) {
      setFaceData(detectedFaceData);
      setIsFace(true);
      setOpenSnackbar(true);
      
      formik.setValues({
        email: "face_login@dummy.com",
        password: "face_login",
        remember: false,
      });
      
      setTimeout(() => {
        formik.handleSubmit();
        setIsLoading(false);
      }, 1000);
    }else{
      setIsLoading(false);
    }
  };

  const handleEmailCondition = () => {
    setIsEmailPass(true);
    setIsFace(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {isEmailPass && (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
        )}

        {isEmailPass && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps("remember")} checked={values.remember} />}
              label="Remember me"
            />
            <Link component={RouterLink} variant="subtitle2" to="/forgot-password">
              Forgot password?
            </Link>
          </Stack>
        )}

        {isEmailPass && (
          <LoadingButton sx={{ mb: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>
        )}

        {!isEmailPass && (
          <>
            <LoadingButton fullWidth sx={{ mb: 2 }} size="large" variant="contained" onClick={handleEmailCondition}>
              Login by Email
            </LoadingButton>

            <LoadingButton fullWidth size="large" variant="contained" onClick={handleFaceLogin} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Login by Face"}
            </LoadingButton>
          </>
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          message="Face captured successfully! Logging in..."
        />
      </Form>
    </FormikProvider>
  );
}
