import DialogContentText from "@mui/material/DialogContentText";
import {
    Box,
    FormControl,
    OutlinedInput,
    InputAdornment,
    InputLabel,
    Typography,
    Button,
    Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WALLET_RECOMMENDATION_OPTIONS } from "../../../constants/content.constant";
import { useState, useEffect } from "react";
import { WalletPaymentMethod } from "../../../components/paymentAction/WalletPaymentAction";
import useModal from "../../../hooks/useModal";
import { useCart } from "../../../contexts/cart/use-cart";
import { NoCartBag } from "../../../assets/icons/NoCartBag";
import { OrderItemList } from "../../../components/order-item-list/order-item-list";
import toastr from "toastr";
import { MESSAGE } from "../../../constants/content.constant";
import { useNavigate } from 'react-router-dom';
import { ROUTES_URL } from "../../../constants/url.constant";
import { getMethod } from "../../../utils/api";
import { API_URL } from "../../../constants/url.constant";
import { API_STATUS_CODE } from "../../../constants/content.constant";
import { checkUser } from "../../../utils/checkUser";
import { PaymentMethod } from "../../../components/paymentAction/PaymentAction";


//CSS
import { NoProductImg, ItemWrapper, } from "./cart.style";
import useMachineState from "../../../utils/CheckMachineState";
import { WithoutPayOrder } from "../../../components/paymentAction/WithoutPayOrder";
import { getLocalData } from "../../../utils/helper";
export const useStyles = makeStyles({
    inputSize: {
        "& input": {
            width: '2.5em !important',
            height: '2.5em',
            margin: '8px 4px',
            background: '#f6f5fa',
            border: 'none',
            borderRadius: '8px'
        }
    }
})


export default function ConfirmOrder() {
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isModalOpen, showModal, hideModal } = useModal();

    const machineState = true
    // const machineState = useMachineState()
    const company_id = getLocalData('company_id');
    const dataIDS = [
        // '6478511a3ae4aa133000d4a0',
        '67a982ae8a1274790e0fedfa'
    ]
    const [orderQueue, setOrderQueue] = useState(null);
    const {
        items,
        addItem,
        removeItem,
        removeItemFromCart,
        cartItemsCount,
        calculatePrice,
    } = useCart();

    const payDetails = {
        total_amount: calculatePrice(),
        title: '',
        items: items
    };
    const user = checkUser();

    if (items.length == 0) {
        // navigate(ROUTES_URL.HOME, { replace: true });
    }

    const MaxItemAlert = () => {
        toastr.success(MESSAGE.MAX_ITEM_LIMIT_ALERT);
        return false

    }

    const generateMessage = (item) => {
        let message = null;
        
        let index = (orderQueue?.findIndex(queue => queue.machineCode == item.machine[0]?.codeno));
        if (index >= 0) {
            message = "Machine is already Proccessing " + orderQueue[index].queue + " Order of this item, Your order might get delayed"
        } if(!machineState){
            message = "Machine is busy in Proccessing Other order"
        }
         else {
            message = "Machine is ready to proccess the order for this item"
        }
        return message

    }

    function handleProceedClick() {
        if (!user) {
            PaymentMethod(payDetails)
        } else {
            navigate("/payment-options", { replace: true });
            hideModal()
        }
    }
    function directOrderProceed() {
        if (user) {
            WithoutPayOrder(payDetails)
        } else {
            navigate("/login", { replace: true });
            hideModal()
        }
    }


    function handleCancelClick() {
        navigate(ROUTES_URL.HOME, { replace: true });
    }

    const classes = useStyles();


    const fetchTokenList = async () => {
        try {
            // setLoading(true);
            const response = await getMethod(API_URL.GET_TOKEN_LIST, false, true);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                setOrderQueue(response.data.data.orderQueue)
            }
            // setLoading(false);
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (amount >= 0) {
            setIsLoading(false)
        }

        fetchTokenList()
    }, [amount]);
    return (
        <DialogContentText align="center">
            <Box
                component="span"
                display="block"
                sx={{ fontSize: "16px", fontWeight: "600", m: 0.5 }}
            >
            </Box>

            <Box>
                <ItemWrapper className="items-wrapper">
                    {!!cartItemsCount() ? (
                        items.map((item, i) => (
                            <OrderItemList
                                key={`cartItem-${item._id + i}`}
                                onIncrement={() => (cartItemsCount() === 3) ? MaxItemAlert() : (addItem(item))}
                                onDecrement={() => removeItem(item)}
                                onRemove={() => removeItemFromCart(item)}
                                data={item}
                                warning={generateMessage(item)}
                            />
                        ))
                    ) : (
                        <>
                            <NoProductImg>
                                <NoCartBag />
                            </NoProductImg>
                        </>
                    )}
                </ItemWrapper>
            </Box>

            <Grid container direction="row" spacing={3} sx={{ mt: 5 }}>
                <Grid item md={6} sm={12} xs={12}>
                    <Button onClick={() => handleCancelClick()} fullWidth size="large" color="warning" variant="contained">
                        {/* <Icon icon={WalletIcon} color="#DF3E30" height={24} />  */} Cancel
                    </Button>
                </Grid>
                {!dataIDS.includes(company_id)?
                <Grid item md={6} sm={12} xs={12}>
                    <Button disabled={!machineState} onClick={() => handleProceedClick()} fullWidth size="large" color="primary" variant="contained">
                        {/* <Icon icon={WalletIcon} color="#DF3E30" height={24} />  */}Confirm and Pay
                    </Button>
                </Grid>:
                <Grid item md={6} sm={12} xs={12}>
                    <Button disabled={!machineState} onClick={() => directOrderProceed()} fullWidth size="large" color="primary" variant="contained">
                        {/* <Icon icon={WalletIcon} color="#DF3E30" height={24} />  */}Place Order
                    </Button>
                </Grid>}
            </Grid>
        </DialogContentText>
    );
}
