import React, { useEffect, useState } from "react";
import { getLocalData } from "../../utils/helper";
import useMachineState from "../../utils/CheckMachineState";
import { postMethod } from "../../utils/api";
import { API_URL } from "../../constants/url.constant";

function MachineStausPopup(props) {
  const machineState1 = useMachineState(props.data);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
   askPermission()
  }, []);
  const dataIds = [
    "63be8c49ae309fb48bbc4ffe",
    "63bffd6dbf952d2d355a63b8",
    "63e4ba79cb6f40797a3d9fdb",
  ]
  const askPermission = ()=>{
    if(dataIds.includes(id)){
    const confirmPermission = window.confirm("Do you want to Machine Status?");
    setPermit(confirmPermission);
  }}
  const userData = getLocalData("user");
  const data = userData ? JSON.parse(userData) : {};
  const id = data.user_id;
  const [show, setShow] = useState(false);
  const [formState, setFormState] = useState({});
  const handleChange = (e) => {
    e.preventDefault();
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
}

const handleSubmit = async () => {
    try{
        let response;
        response = await postMethod(
            API_URL.POST_COUSTOM_ORDER,
            formState,
            false,
            false
          );
    }catch{

    }
}
const handleClose=()=>{
    setFormState()
    setShow(false)
}
  return (
    <div>
      {/* {show && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000000,
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form className="card w-75 h-50 p-2">
            <button
              style={{ position: "absolute", right: 4 }}
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={()=>handleClose()}
            ></button>
            <h4 className="text-secondary">Enter Values</h4>
            <div className="row p-1">
              <div className="col-md-6 my-3">
                <label>Machine Code</label>
                <input
                  name="codeno"
                  value={formState?.codeno||''}
                  onChange={(e) => handleChange(e)}
                  type="number"
                  className="form-control"     
                  required={true}

                />
              </div>
              <div className="col-md-6 my-3">
                <label>Recipe Code</label>
                <textarea
                  name="recipe"
                  value={formState?.recipe||''}
                  onChange={(e) => handleChange(e)}
                  rows={4}
                  type="text"
                  className="form-control"
                  required={true}
                />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end">
              <button onClick={()=>handleClose()} className="btn btn-warning m-1">Cancel</button>
              <button onClick={()=>handleSubmit()} className="btn btn-success m-1">Submit</button>
            </div>
          </form>
        </div>
      )} */}
      {(dataIds.includes(id)&&permit) && (
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <div className="d-flex justify-content-between">
            <h4 class="alert-heading">Machine Status</h4>
            {/* <button onClick={()=>setShow(true)} className="btn btn-primary">Custom Order</button> */}
          </div>
          <hr></hr>
          <ol>
            {machineState1?.statusMessages?.map((i) => (
              <li>
                <p class="mb-0">{i}</p>
              </li>
            ))}
          </ol>

        </div>
      )}
    </div>
  );
}

export default MachineStausPopup;
