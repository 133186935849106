// src/utils/faceUtils.js
import * as faceapi from 'face-api.js';

export const loadModels = async () => {
  try {
    const modelUrl = `${window.location.origin}/models`;

    // Load models before running any face detection
    await faceapi.nets.ssdMobilenetv1.loadFromUri(modelUrl); // ✅ Object detection
    await faceapi.nets.faceRecognitionNet.loadFromUri(modelUrl); // ✅ Face recognition
    await faceapi.nets.faceLandmark68Net.loadFromUri(modelUrl); // ✅ Face landmarks
    await faceapi.nets.tinyFaceDetector.loadFromUri(modelUrl); // ✅ Add this if using TinyFaceDetector

    console.log('✅ Models loaded successfully on Frontend!');
  } catch (error) {
    console.error('❌ Error loading models:', error);
  }
};


export const captureFaceData = async (videoRef) => {
  try {
    // Make sure models are loaded before detection
    if (
      !faceapi.nets.ssdMobilenetv1.isLoaded ||
      !faceapi.nets.faceRecognitionNet.isLoaded ||
      !faceapi.nets.faceLandmark68Net.isLoaded ||
      !faceapi.nets.tinyFaceDetector.isLoaded
    ) {
      console.error('❌ Models are not yet loaded!');
      await loadModels(); // Try to reload models if not loaded
    }

    // Run face detection once models are loaded
    const detections = await faceapi
      .detectAllFaces(videoRef.current, new faceapi.SsdMobilenetv1Options())
      .withFaceLandmarks()
      .withFaceDescriptors();

    if (!detections.length) {
      console.error('⚠️ No face detected. Please try again.');
      return null;
    }

    console.log('✅ Face detected:', detections);
    return detections;
  } catch (error) {
    console.error('❌ Error capturing face data:', error);
    return null;
  }
};



export const compareFaces = (capturedDescriptor, storedDescriptors) => {
  const faceMatcher = new faceapi.FaceMatcher(
    storedDescriptors.map((d) => new faceapi.LabeledFaceDescriptors(d.label, [new Float32Array(d.descriptor)]))
  );

  const bestMatch = faceMatcher.findBestMatch(new Float32Array(capturedDescriptor));
  return bestMatch;
};


export const stopCamera = () => {
  console.log('stopCamera')
  navigator.mediaDevices.getUserMedia({ video: true })
    .then((stream) => {
      stream.getTracks().forEach(track => track.stop());
    })
    .catch((error) => console.error("Error stopping camera:", error));
};