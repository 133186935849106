import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import { postMethod } from '../../../utils/api';
import { API_URL } from '../../../constants/url.constant';
import { API_STATUS_CODE } from '../../../constants/content.constant';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  InputBase,
  Button,
  Box,
  Typography

} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ROUTES_URL } from "../../../constants/url.constant";
import { registerUserAsyncThunk } from '../../../store/slices/user.slice';
import { useDispatch, useSelector } from "react-redux";
import { checkUser } from '../../../utils/checkUser';
import IconCoupon from '../../../assets/images/coupon2.png'


// ----------------------------------------------------------------------

export default function RegisterForm({face,handleCaptureFace}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUser = checkUser();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (isUser) {
      navigate(ROUTES_URL?.HOME, { replace: true });

    }
  })

  useEffect(() => {
    applyCouponCode()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedCoupon]);


  const applyCouponCode = () => {
    let discountAmount = 0
    if (appliedCoupon && appliedCoupon?.type === "WELCOME") {
      discountAmount = appliedCoupon.value
    }
    setDiscount(discountAmount)
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    contact: Yup.string().required('Contact number is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      password: '',
      discount: 0,
      faceData: face
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      try {
        const capturedFace = await handleCaptureFace();
        
        if (!capturedFace) {
          setMessage("Face capture failed. Please try again.");
          return;
        }
  
        values.faceData = capturedFace;
        values.discount = discount;
  
        const response = await dispatch(registerUserAsyncThunk(values));
  
        // Check if registration is successful
        if (!response?.error) {
          navigate(ROUTES_URL?.HOME, { replace: true });
        }
      } catch (error) {
        console.error("Registration failed:", error);
      }
    }
  });
  

  const handleApplyClick = async () => {
    if (!selectedCoupon) {
      setMessage("Please enter a valid Coupon Code")
      return
    }
    try {
      const response = await postMethod(API_URL.VERIFY_COUPON, { coupon: selectedCoupon }, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS &&
        response?.data?.data?.type === "WELCOME") {
        setMessage("Coupon Code " + selectedCoupon.toUpperCase() + " applied succesfully");
        setAppliedCoupon(response?.data?.data);


      } else {
        setMessage("Coupon Code is invalid or expired")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCoupon = (event) => {
    setMessage(null)
    setDiscount(0)
    setAppliedCoupon(null)
    setSelectedCoupon(event.target.value);
  };

  const CouponCodeInputAndVerify = () => {
    return (<Stack sx={{ mt: 2, pt: 2 }} direction="column">

      <Box sx={{ mb: 1 }}>
        <Typography sx={{ color: "text.secondary" }}>
          {message}
        </Typography>
        {
          appliedCoupon ?
            <Typography sx={{ color: "text.secondary" }}>
              You will receive wallet amount of ₹  {discount} from Clover Carte
            </Typography>
            :
            ""
        }
      </Box>

      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", border: "1px solid green" }}
      >
        <IconButton sx={{ p: '5px' }} aria-label="menu">
          <img height="35px" src={IconCoupon}></img>
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Type Refferal or Coupon Code"
          onChange={handleChangeCoupon}
          value={selectedCoupon}
          inputProps={{ style: { textTransform: "uppercase" } }}
          fullWidth

        />
        <Button
          onClick={() => handleApplyClick()}
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          disabled={selectedCoupon == null ? true : false}
        >
          Apply
        </Button>
      </Paper>
    </Stack>
    );
  }


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="contact"
            label="Contact Number"
            {...getFieldProps('contact')}
            error={Boolean(touched.contact && errors.contact)}
            helperText={touched.contact && errors.contact}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {CouponCodeInputAndVerify()}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
